import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled, {css, ThemeProvider} from 'styled-components';
import {connect} from 'react-redux';
import Input from 'components/form/input';
import {getMinutesAndSeconds, getSeconds} from 'utils/date';
import ButtonBase from 'components/button/button';
import Tomato from 'components/svg/tomato';
import {Row} from 'components/wrappers';
import Select from 'components/form/select';
import CheckInput from 'components/form/check-input';
//import {setItem, getItem} from 'utils/localStorage';
import pomodoroWorker from 'workers/pomodoro-worker';
import {
  actions as pomodoroActions,
  timerStatuses,
  sessionTypes,
  soundOptions,
} from 'state/pomodoro';
import {actions as audioActions} from 'state/audio';
import theme from 'assets/theme';
//https://codepen.io/rvinluan/pen/LVGgVa

//SVG settings
const sqr = `${400}px`;
const stroke = 252;
const end = 0;
const start = end - stroke;
//Session Types
const {TIMER_STOPPED, TIMER_PAUSED, TIMER_RUNNING} = timerStatuses;
const {WORK, SMALL_BREAK, BIG_BREAK} = sessionTypes;

const text = {
  [WORK]: {
    name: 'Works Session',
    description: 'Time to knuckle down, no distractions.',
  },
  [SMALL_BREAK]: {
    name: 'Small Break',
    description: 'Chillax bro, grab a tea, go to the toilet.',
  },
  [BIG_BREAK]: {
    name: 'Big Break',
    description: 'Hey hard worker, you have earned a big break.',
  },
};
//Wrappers
const Container = styled.div`
  background-color: ${props => props.theme.colors.sideBar};
  color: #fff;
  padding: 30px;

  h2 {
    color: #fff;
  }
`;
const CountDown = styled.div`
  position: relative;
  margin: auto;
  margin-bottom: 10px;
  height: ${sqr};
  width: ${sqr};
  text-align: center;
`;
const RemainingTime = styled.div`
  color: white;
  display: inline-block;
  line-height: ${sqr};
  font-size: 60px;
`;
const SVG = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  transform: rotateY(-180deg) rotateZ(-90deg);
`;
const Description = styled.div`
  position: absolute;
  top: 75px;
  height: ${sqr};
  width: ${sqr};
`;
const ButtonGroup = styled.div`
  position: absolute;
  top: 232px;
  height: ${sqr};
  width: ${sqr};
`;
const Button = styled(ButtonBase)`
  color: white;
  background: transparent;
  border: 2px solid white;
  font-size: 24px;
  height: 60px;
  width: 60px;
  border-radius: 50%;

  ${props =>
    props.textButton &&
    css`
      width: auto;
      height: 30px;
      border-radius: 2px;
      font-size: 12px;
    `}

  i,
  svg {
    margin: 0;
  }
  ${props => props.theme.focusOutlineLink}
  &:hover {
    color: ${props => props.theme.colors.link};
    border: 2px solid ${props => props.theme.colors.link};
  }
`;
const Tomatoes = styled.div`
  svg {
    margin: 10px;
  }
`;
const Stats = styled.div`
  width: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;
const Stat = styled.div`
  margin: 20px;
  text-align: center;
  ${props =>
    props.primary &&
    css`
      transform: scale(1.5);
    `}
`;
const Count = styled.div`
  font-size: 40px;
  margin: 20px;
`;
const Label = styled.div`
  font-size: 16px;
`;
const Hr = styled.hr`
  border-top: 3px dashed rgba(256, 256, 256, 0.3);
  margin-bottom: 60px;
  margin-top: 60px;
`;
const SessionSettingsForm = styled.form`
  div.Input {
    display: flex;
    flex-direction: column;
  }
  label {
    display: block;
    margin: 20px 0 40px;
    text-align: center;
    font-size: 14px;
  }

  input {
    margin: 0 auto;
    border: 2px solid #fff;
    background: transparent;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    text-align: center;
    color: #fff;
    font-size: 32px;
    ${props => props.theme.focusOutlineLink}
  }
`;
const PlaySessionSoundForm = styled.div`
  margin-bottom: 20px;

  label.custom-control-label {
    color: #fff !important;
    font-size: 14px;
  }

  input:focus + label:before {
    outline: 2px solid ${props => props.theme.colors.link};
  }
`;
const SoundSettingsForm = styled.form`
  div.Select-Container {
    position: relative;
    display: flex;
    flex-direction: column;

    &::after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      pointer-events: none;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      top: 22px;
      right: 10px;
      border-top: 8px solid #fff;
      opacity: 0.5;
    }
  }
  label {
    display: block;
    margin: 0 0 20px;
    font-size: 14px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 14px;
    color: #fff;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 3px;
    padding: 14px 28px 14px 14px;
    font-size: 14px;
    &::-ms-expand {
      display: none;
    }
    ${props => props.theme.focusOutlineLink}
    ${props => props.theme.disabledInput}
  }
`;

//const LS_KEY = 'PRODUCTIVITY-POMODORO_TIMER';

class Pomodoro extends Component {
  get sessionInProgress() {
    return this.props.timerStatus === TIMER_RUNNING;
  }
  get settingsForm() {
    const {playSessionEndSound, restoreDefaultSettings} = this.props;
    const work = this.props[WORK];
    const sb = this.props[SMALL_BREAK];
    const bb = this.props[BIG_BREAK];
    return (
      <Fragment>
        <Hr />
        <SessionSettingsForm>
          <Row>
            <div className="col-md-6">
              <Input
                naked
                min={1}
                max={120}
                label="Work session duration (minutes)"
                handleChange={this.handleInputChange}
                id={`${WORK}.duration`}
                value={work.duration}
                type="number"
              />
            </div>
            <div className="col-md-6">
              <Input
                naked
                min={1}
                max={120}
                label="Small break duration (minutes)"
                handleChange={this.handleInputChange}
                id={`${SMALL_BREAK}.duration`}
                value={sb.duration}
                type="number"
              />
            </div>
          </Row>
          <Row>
            <div className="col-md-6">
              <Input
                naked
                min={1}
                max={120}
                label="Big break duration (minutes)"
                handleChange={this.handleInputChange}
                id={`${BIG_BREAK}.duration`}
                value={bb.duration}
                type="number"
              />
            </div>
            <div className="col-md-6">
              <Input
                naked
                min={2}
                max={10}
                label="Big break interval (number of work sessions)"
                handleChange={this.handleInputChange}
                id={`${BIG_BREAK}.interval`}
                value={bb.interval}
                type="number"
              />
            </div>
          </Row>
        </SessionSettingsForm>
        <Hr />
        <PlaySessionSoundForm>
          <CheckInput
            naked
            id="playSessionEndSound"
            handleChange={this.handleInputChange}
            label="Play session end sound"
            checked={playSessionEndSound}
          />
        </PlaySessionSoundForm>
        <SoundSettingsForm>
          <Row>
            <div className="col-md-4">
              <Select
                disabled={!playSessionEndSound}
                options={soundOptions}
                naked
                topLabel
                label="Work session end sound"
                handleChange={this.handleInputChange}
                id={`${WORK}.sessionEndSound`}
                value={work.sessionEndSound}
              />
            </div>
            <div className="col-md-4">
              <Select
                disabled={!playSessionEndSound}
                options={soundOptions}
                naked
                topLabel
                label="Small break end sound"
                handleChange={this.handleInputChange}
                id={`${SMALL_BREAK}.sessionEndSound`}
                value={sb.sessionEndSound}
              />
            </div>
            <div className="col-md-4">
              <Select
                disabled={!playSessionEndSound}
                options={soundOptions}
                naked
                topLabel
                label="Big break end sound"
                handleChange={this.handleInputChange}
                id={`${BIG_BREAK}.sessionEndSound`}
                value={bb.sessionEndSound}
              />
            </div>
          </Row>
        </SoundSettingsForm>
        <Hr />
        <Button textButton handleClick={restoreDefaultSettings}>
          Restore default settings
        </Button>
      </Fragment>
    );
  }
  get tomatos() {
    let pomodoros = this.props[WORK].count;

    if (!pomodoros) {
      return null;
    }
    const tomatoes = [];
    while (pomodoros > 0) {
      tomatoes.push(<Tomato square={70} key={pomodoros} />);
      pomodoros--;
    }
    return <Tomatoes>{tomatoes}</Tomatoes>;
  }
  get currentSessionProps() {
    const {currentSession} = this.props;
    return this.props[currentSession];
  }
  get offset() {
    const {remainingTime} = this.props;

    if (remainingTime !== end) {
      const totalTime = getSeconds(this.currentSessionProps.duration);
      return (start * remainingTime) / totalTime;
    }

    return 0;
  }
  async componentDidMount() {
    const worker = pomodoroWorker(data => {
      if (data && data === 'SESSION_ENDED') {
        this.playSessionEndSound();
      }
    });
    this.worker = worker;
    const {remainingTime, sessionInProgress, sessionEnded} = await worker.getStatus();

    if (sessionEnded) {
      //Set Next Session ready to be started by user
      this.setNextSession();
    }

    if (sessionInProgress) {
      //Stop web worker timer and start component timer.
      const {setRemainingTime} = this.props;

      setRemainingTime({remainingTime});
      worker.clearTimer();
      this.startTimer();
    }
  }
  componentWillUnmount() {
    const {remainingTime} = this.props;
    if (this.sessionInProgress) {
      this.worker.setTimer(remainingTime);
    }
    this.clearTimer();
    this.updateLocalStorage();
  }
  updateLocalStorage = () => {
    //setItem(LS_KEY, this.props);
  };
  handleInputChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const checked = e.target.checked;
    const [session, key] = name.split('.');
    const {handleInputChange, currentSession, setRemainingTime} = this.props;

    handleInputChange({name, value, checked});

    if (key === 'duration' && currentSession === session) {
      setRemainingTime();
    }
  };
  stop = () => {
    this.props.stopTimer();
    this.clearTimer();
  };
  pause = () => {
    this.props.pauseTimer();
    this.clearTimer();
  };
  startTimer = () => {
    const {startTimer, pauseTimer, decrementRemainingTime, stopAudio} = this.props;
    stopAudio();
    startTimer();
    this.timer = setInterval(() => {
      if (this.props.remainingTime > 0) {
        decrementRemainingTime();
      } else {
        pauseTimer();
        this.playSessionEndSound();
        this.setNextSession();
      }
    }, 1000);
  };
  setNextSession = () => {
    this.clearTimer();
    const {
      incrementCurrentSessionCount,
      setNextSession,
      setRemainingTime,
      pauseTimer,
    } = this.props;

    pauseTimer();
    incrementCurrentSessionCount();
    setNextSession();
    setRemainingTime();
  };
  clearTimer = () => {
    clearInterval(this.timer);
    this.timer = null;
  };
  skip = () => {
    this.setNextSession();
    this.startTimer();
  };
  playSessionEndSound = () => {
    const {playSessionEndSound, setAudioSource, playAudio} = this.props;
    if (playSessionEndSound) {
      setAudioSource({src: this.currentSessionProps.sessionEndSound});
      playAudio();
    }
  };
  get playPauseButtonProps() {
    if (this.sessionInProgress) {
      return {
        icon: 'control-pause',
        handleClick: this.pause,
        title: 'Pause Timer',
        ariaLabel: 'Pause Timer',
      };
    }
    return {
      icon: 'control-play',
      handleClick: this.startTimer,
      title: 'Start Timer',
      ariaLabel: 'Start Timer',
    };
  }
  render() {
    const {props} = this;
    const {currentSession, remainingTime, timerStatus} = props;
    const {name, description} = text[currentSession];
    const {minutesAndSeconds} = getMinutesAndSeconds(remainingTime);
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <Row>
            <div className="col-md-12 col-lg-7">
              <CountDown square={sqr}>
                <Description>
                  <h2>{name}</h2>
                  <p>{description}</p>
                </Description>
                <RemainingTime>{minutesAndSeconds}</RemainingTime>
                <SVG
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width={sqr}
                  height={sqr}
                  viewBox="0 0 78 78"
                >
                  <defs>
                    <circle id="a" cx="39" cy="39" r="35" />
                  </defs>
                  <g
                    strokeDashoffset={this.offset}
                    fill="none"
                    fillRule="evenodd"
                    stroke="#fff"
                    strokeWidth="3"
                  >
                    <use
                      strokeDasharray={`${stroke} ${stroke}`}
                      transform="rotate(0 39 39)"
                      xlinkHref="#a"
                    />
                    <use strokeOpacity="0.3" xlinkHref="#a" />
                  </g>
                </SVG>
                <ButtonGroup>
                  {
                    <Button
                      icon="control-stop"
                      transparent
                      handleClick={this.stop}
                      title="Stop Timer"
                      ariaLabel="Stop Timer"
                      visible={timerStatus !== TIMER_STOPPED}
                      disabled={timerStatus === TIMER_STOPPED}
                      transform="scale(0.9)"
                    />
                  }
                  {
                    <Button
                      {...this.playPauseButtonProps}
                      transparent
                      margin="20px"
                      transform="scale(1.2)"
                    />
                  }
                  <Button
                    icon="control-skip-forward"
                    transparent
                    handleClick={this.skip}
                    title="Skip Session"
                    ariaLabel="Skip Session"
                    transform="scale(0.9)"
                  />
                </ButtonGroup>
              </CountDown>
              <Stats>
                <Stat>
                  <Count>{props[SMALL_BREAK].count}</Count>
                  <Label>Small breaks</Label>
                </Stat>
                <Stat primary>
                  <Count>{props[WORK].count}</Count>
                  <Label>Pomodoros</Label>
                </Stat>
                <Stat>
                  <Count>{props[BIG_BREAK].count}</Count>
                  <Label>Big breaks</Label>
                </Stat>
              </Stats>
              {this.settingsForm}
            </div>
            <div className="col-md-12 col-lg-5">{this.tomatos}</div>
          </Row>
        </Container>
      </ThemeProvider>
    );
  }
}

Pomodoro.layoutHeader = {
  h1: 'Pomodoro Timer',
  intro: ['Pomodoro means `tomato` in Italian.'],
};

Pomodoro.propTypes = {
  //state
  playSessionEndSound: PropTypes.bool.isRequired,
  [WORK]: PropTypes.shape({
    sessionEndSound: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
  }),
  [SMALL_BREAK]: PropTypes.shape({
    sessionEndSound: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
  }),
  [BIG_BREAK]: PropTypes.shape({
    sessionEndSound: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    interval: PropTypes.number.isRequired,
  }),
  currentSession: PropTypes.oneOf([WORK, SMALL_BREAK, BIG_BREAK]).isRequired,
  remainingTime: PropTypes.number.isRequired,
  timerStatus: PropTypes.oneOf([TIMER_STOPPED, TIMER_RUNNING, TIMER_PAUSED]).isRequired,
  //Pomodoro Methods
  restoreDefaultSettings: PropTypes.func.isRequired,
  pauseTimer: PropTypes.func.isRequired,
  stopTimer: PropTypes.func.isRequired,
  startTimer: PropTypes.func.isRequired,
  decrementRemainingTime: PropTypes.func.isRequired,
  setNextSession: PropTypes.func.isRequired,
  incrementCurrentSessionCount: PropTypes.func.isRequired,
  setRemainingTime: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  //Audio State
  src: PropTypes.string,
  status: PropTypes.string.isRequired,
  //Audio Methods
  setAudioSource: PropTypes.func.isRequired,
  playAudio: PropTypes.func.isRequired,
  stopAudio: PropTypes.func.isRequired,
};

export default connect(state => ({...state.pomodoro, ...state.audio}), {
  ...pomodoroActions,
  ...audioActions,
})(Pomodoro);
